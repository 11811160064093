$primary-color: #26104e;
$secondary-color: #f5e711;

.headerContainer {
  background: $primary-color;
  top: -100px;
  &.headerSticky {
    position: sticky;
    top: 0;
    z-index: 2;
    transition: 0.5s;
  }

  .header {
    width: 1124px;
    max-width: 100%;
    margin: auto;
    height: 80px;
    display: flex;
    justify-content: space-between;
    gap: 90px;

    img {
      width: 200px;
      padding: 18px 0px;
      cursor: pointer;
    }

    h2 {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #010414;
      margin: 0;
      align-self: center;
    }

    @media only screen and (max-width: 690px) {
      height: 135px;
      flex-direction: column;
      align-items: center;
      gap: 0px;
      justify-content: space-around;
      img {
        padding: 0;
        margin-top: 5px;
      }
    }
    @media only screen and (max-width: 785px) {
      gap: 0px;
    }

    .moto {
      align-self: center;
      color: #f5e711;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      gap: 10px;

      svg {
        margin-top: 1px;
        width: 115px;
      }
      @media only screen and (max-width: 328px) {
        margin-left: 40px;
      }
      .motoText {
        align-self: center;
        font-size: 23px;
        margin-top: -5px;
        @media only screen and (max-width: 785px) {
          color: #fff;
        }
      }
    }

    .socialContainer {
      display: flex;
      align-items: center;
      svg {
        padding: 0;
        width: 25px;
        height: 25px;
        margin-top: 3px;
        color: #fff;
        path {
          fill: #fff;
        }
      }

      .facebookBox {
        width: 35px;
        height: 35px;
        display: inline-block;
        border: 1px solid transparent;
        text-align: center;
        line-height: 35px;
        color: #fff;
        border-radius: 4px;
        margin-right: 3px;
        transition: background-color 0.15s;
        cursor: pointer;
        &:hover {
          background: #3f51b5;
        }
      }

      .instagramBox {
        width: 35px;
        height: 35px;
        display: inline-block;
        border: 1px solid transparent;
        text-align: center;
        line-height: 35px;
        color: #fff;
        border-radius: 4px;
        margin-right: 3px;
        transition: background-color 0.15s;
        cursor: pointer;
        &:hover {
          background: #87336d;
        }
      }
    }
  }
}
