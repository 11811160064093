.footerContainer {
  background: #26104e;
  padding: 0 10px;
  margin-top: 20px;

  .footer {
    width: 1124px;
    max-width: 100%;
    margin: auto;
    padding: 0 20px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    @media only screen and (max-width: 785px) {
      height: 60px;
    }

    .footerTexts {
      display: flex;
      flex-direction: column;
      gap: 5px;
      @media only screen and (max-width: 785px) {
        font-size: 11px;
        gap: 0px;
      }

      .createdBy {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .logo {
      img {
        height: 60px;
        padding: 18px 0px;
        cursor: pointer;
      }
    }
  }
}
